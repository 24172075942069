<template>
  <div style="background-color: #fafafa">
    <Myhead :nav="1" :nav3="4"></Myhead>
    <div class="header">
      <div class="header-text">
        <div class="header-title">{{ Language.technicalAdvice.technical_advice }}</div>
        <div class="btn-contactUs" @click="tocontactUs">{{ Language.technicalAdvice.Contact_Us }}</div>
      </div>
    </div>
    <div class="router">
      <div class="routerbox">
        <router-link to="/" class="routerLink" >{{ Language.technicalAdvice.home }}</router-link> > <router-link to="/our-services" class="routerLink" >
        {{ Language.technicalAdvice.Our_services }}</router-link> >
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer">&nbsp;{{ Language.technicalAdvice.technical_advice }}</span>
      </div>
    </div>
    <div class="container">
      <div class="container-introduce">
        {{ Language.technicalAdvice.msg1 }}
      </div>
      <div class="abstract">

        <div class="textbox">
          <ul>
            <li><div class="abstract-title">{{ Language.technicalAdvice.technical_advice }}</div></li>
            <li>{{ Language.technicalAdvice.msg2 }}</li>
          </ul>
        </div>
        <div class="abstract-img">
          <img src="../assets/img/technicalAdvice/1.jpg" alt="技术咨询"/>
        </div>
      </div>

      <!--检验类型-->
      <div class="typebox">
        <div class="item">

          <div class="flexbox">
            <div class="content show">
              <div class="title">{{ Language.technicalAdvice.Supplier_Quality_Assessment }}</div>
              {{ Language.technicalAdvice.msg3 }}
            </div>
            <div class="img">
              <img src="../assets/img/technicalAdvice/2.jpg" alt="技术咨询"/>
            </div>
            <div class="content hide">
              <div class="title">{{ Language.technicalAdvice.Supplier_Quality_Assessment }}</div>
              {{ Language.technicalAdvice.msg3 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="content" style="margin-left: 0;margin-right: 10px">
              <div class="title">{{ Language.technicalAdvice.Ziniu_Quality_Management_Empowerment_System }}</div>
              {{ Language.technicalAdvice.msg4 }}
            </div>
            <div class="img">
              <img src="../assets/img/technicalAdvice/3.jpg" alt="技术咨询"/>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="content show">
              <div class="title">{{ Language.technicalAdvice.Real_time_communication_and_efficient_technical_services }}</div>
              {{ Language.technicalAdvice.msg5 }}
            </div>
            <div class="img">
              <img src="../assets/img/technicalAdvice/4.jpg" alt="技术咨询"/>
            </div>
            <div class="content hide">
              <div class="title">{{ Language.technicalAdvice.Real_time_communication_and_efficient_technical_services }}</div>
              {{ Language.technicalAdvice.msg5 }}
            </div>
          </div>
        </div>
      </div>

      <div class="abstract bottombox">
        <div>
          <div class="abstract-title" style="font-size: 30px">{{ Language.technicalAdvice.msg6 }}</div>
          <ul>
            <li style="font-size: 17px;margin-top: 20px">{{ Language.technicalAdvice.msg7 }}</li>
            <li style="font-size: 17px;margin-top: 20px">{{ Language.technicalAdvice.msg8 }}</li>
            <li style="font-size: 17px;margin-top: 20px">{{ Language.technicalAdvice.msg9 }}</li>
            <li style="font-size: 17px;margin-top: 20px">{{ Language.technicalAdvice.msg10 }}</li>
          </ul>
        </div>
      </div>


<!--      <div class="container-introduce">-->
<!--        我国政府和电商平台都非常重视电商服务检测，以保障消费者权益。通过检测，可以提高电商平台的产品质量，促进市场健康发展-->
<!--      </div>-->

    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    Myhead,
    Myfoot
  },
  methods:{
    tocontactUs(){
      this.$router.push("/contact")
    },
  }
}
</script>
<style lang="scss" scoped>

.show{
  display: none;
}

.hide{
  display: block;
}

.header {
  margin-top: -70.5px;
  width: 100%;
  height: 360px;
  color: #FFFFFF;
  background-image: url("../assets/img/certificateService/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header-text {
    max-width: 1200px;
    margin: auto;
    padding-top: 150px;

    .header-title {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
}

.router {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;
  .routerbox{
    width: 1200px;
    margin: auto;
  }
}

.container {
  width: 1200px;
  margin: auto;
  //padding: 0 360px;
  color: var(--title-color);

  .container-introduce {
    margin: 80px 0;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .abstract {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .textbox{
      width: 640px;
      margin-right: 20px;
    }
    .abstract-title {
      font-size: 42px;
      //margin-left: 10px;
      color: #000000;
    }

    ul {
      color: var(--text-color);

      li {
        margin-bottom: 8px;
      }
    }

    .abstract-img {
      width: 460px;
      height: 260px;

      img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
        //border-radius: 5px;
      }
    }
  }
}

.typebox {
  .item {
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 30px;

    .title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .flexbox {
      display: flex;
    }

    .img {
      width: 50%;
      height: calc(((1200px - 60px) / 2) / 2 * 1);
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .content {
      width: 50%;
      margin-left: 20px;
      font-size: 16px;
    }
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .header{
    .header-text{
      padding: 0px 20px;
      padding-top: 150px;
      .header-title{
        font-size:40px;
      }
      .btn-contactUs{
        width: 130px;
        line-height: 35px;
        height: 35px;
      }
    }
  }
  .router{
    .routerbox{
      padding: 0px 20px;
    }
  }
  .container{
    width: 100%;
    .container-introduce{
      padding: 0px 20px;
      width: 100%;
    }
    .abstract{
      padding: 0px 20px;
    }
  }
  .typebox{
    padding: 0px 20px;
  }
}

@media (min-width: 0px) and (max-width: 800px){
  .show{
    display: block;
  }
  .hide{
    display: none;
  }
  .container{
    .abstract{
      flex-wrap: wrap;
      padding: 0px;
      .textbox{
        width: 100%;
        margin: 0px;
        padding: 0px 20px;
      }
      .abstract-img{
        width: 100%;
        height: auto;
      }
    }
  }
  .typebox{
    padding:  0px;
    .item{
      padding: 20px 0px;
      .flexbox{
        flex-wrap: wrap;
        .img{
          width: 100%;
          height: auto;
        }
        .content{
          width: 100%;
          margin: 0;
          padding: 20px;
        }
      }
    }
  }
  .bottombox{
    padding: 0px 20px !important;
  }
}
@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
</style>
